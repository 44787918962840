import React from 'react';
import styled from 'styled-components';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

function Footer() {
    return (
        <Section>
            <Container>
                <ShareIcons>
                    <ShareRoundedIcon style={{ color: '#565656', fontSize: '25px', marginRight: '1%' }} />
                    <ul style={{ listStyle: 'none', display: 'flex', flexDirection: 'row' }}>
                        <li style={{ backgroundColor: '#3b5998' }}>
                            <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Frealestatemeeting.pt%2F" target='_blank' rel="noreferrer"><FacebookIcon className='social' />
                                <p>Facebook</p>
                            </a></li>
                        <li style={{ backgroundColor: '#00acee' }}>
                            <a href="https://twitter.com/intent/tweet?text=https%3A%2F%2Frealestatemeeting.pt%2F" target='_blank' rel="noreferrer"><TwitterIcon className='social' />
                                <p>Twitter</p></a>
                        </li>
                        <li style={{ backgroundColor: '#0072b1' }}>
                            <a href="https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Frealestatemeeting.pt%2F" target='_blank' rel="noreferrer"><LinkedInIcon className='social' />
                                <p>Linkedin</p></a>
                        </li>
                        <li style={{ backgroundColor: '#00a884' }}>
                            <a href="https://api.whatsapp.com/send?text=https%3A%2F%2Frealestatemeeting.pt%2F" target="_blank" rel="noreferrer"
                                data-action="share/whatsapp/share"><WhatsAppIcon className='social' />
                                <p>WhatsApp</p></a>
                        </li>
                        <li style={{ backgroundColor: '#0a88ff' }}>
                            <a href="mailto:?subject=Real%20Estate%20Meeting%20website&body=https://realestatemeeting.pt/" target="_blank" rel="noreferrer"><AlternateEmailIcon className='social' />
                                <p>Email</p>
                            </a>
                        </li>
                    </ul>
                </ShareIcons>

                <Info>
                    <p>&copy; The Place to Talk Real Estate. {new Date().getFullYear()}</p>
                    <Dev><p>Developed with ❤️ by</p><a href="https://www.estelaricardo.pt" target="_blank" rel="noreferrer"><img src="https://github.com/Estela-Ricardo/raw.images/blob/main/logos/onLight.png?raw=true" alt="dev's logo" /></a></Dev>
                </Info>
            </Container>
        </Section >
    )
}

export default Footer

const Section = styled.div`
    background-color: #f1f1f1;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px;
`
const Container = styled.div`
    max-width: 1400px;
    margin: auto;
    padding: 20px 10px;
    display:flex;
    justify-content: space-between;
    @media (max-width: 768px) {
    flex-direction: column;
    }
`
const ShareIcons = styled.div`
    position: relative;
    display:flex;
    flex-direction: row;
    justify-content: center;
    margin-right: 2%;
    align-items: center;
    .social{
        color: white;
    }
    a{
        display: flex;
        flex-direction: row;
    }
    li{
        border: 1.5px solid transparent;
        align-items:center;
        margin: 0 5px; 
        padding: 5px 10px;
        border-radius: 2px;
        p{
            color: white;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 14px;
            padding-left: 5px;
            margin: auto;
        }
        &:hover {
            cursor:pointer;
            border: 1.5px solid #d39e00;
        }   
    }

    
    @media (max-width: 768px) {
        li{
            margin: 0 2px;
            padding: 3px 8px;
        }
        p{
            display:none;
        }
    }
`
const Info = styled.div`
    display:flex;
    flex-direction: column;
    font-size: small;
    align-items:center;
    padding: 20px 0;
    align-items: right;
    img{
        width: 13px;
        height: 15px;
        &:hover{
            scale: 120%;
        }
    }
`
const Dev = styled.div`
    display:flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0;
    p{
        margin-right: 8px;
    }
`