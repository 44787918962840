import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Fade from 'react-reveal/Fade'

function AboutMe() {
    return (
        <Section>
            <Container>
                <PicsContainer><img src="images/CPprofile.jpg" alt="Cristina Pereira" /></PicsContainer>
                <TextContainer>
                    <Fade>
                        <Text>
                            <h2 style={{ textAlign: 'left' }}>Cristina Pereira</h2>
                        </Text>
                        <Text>
                            <FormattedMessage
                                id="app.about-profile"
                                defaultMessage="About me"
                            />
                        </Text>
                        <Text>
                            <FormattedMessage
                                id="app.about-profile-txt"
                                defaultMessage="After having worked as a marketing director at a company in the luxury sector, I have been passionate about real estate for"
                            /> {new Date().getFullYear() - 2010} <FormattedMessage
                                id="app.about-profile-txt2"
                                defaultMessage="years. My journey over these years is witnessed by the people I come across in this sector; buyers, owners and partners. In each process I give myself my soul, I advise, I say no, say yes. I overcome difficulties, follow my instincts and values so that I can fulfill the desire of those who trust me and believe that emotion and experience command the professional relationships. My mission is to contribute to the achievement of a goal, and it is renewed in every purchase process."
                            />
                        </Text>
                        <Text>
                            <a href="https://www.housedoor.pt/equipa"
                                style={{ textDecoration: 'underline', textDecorationThickness: '1px' }}
                                target="_blank" rel="noreferrer">
                                <FormattedMessage
                                    id="app.about-profile-team"
                                    defaultMessage="The Team"
                                /></a>
                        </Text>
                    </Fade>
                </TextContainer>
            </Container>
        </Section>
    )
}

export default AboutMe

const Section = styled.div`
    background-color: #f1f1f1;
    padding: 20px 0;
`
const Container = styled.div`
    width:100%;
    display: flex;
    align-items: center;
    max-width: 1400px;
    height: 100%;
    margin: auto;
    flex-direction: row;
    justify-content: space-between;
    @media (max-width: 768px) {
        flex-direction: column;
    }
    &:nth-child(1) {
        margin-top: 0px;
    }
`
const PicsContainer = styled.div`
    padding:10px;
    width: 40%;
    img{
        width: 350px;
        height: auto;
        margin: auto;
        display:flex;
        align-items: center;
        max-width: 80%;

    }
    opacity: 0.95;
    @media (max-width: 768px) {
        width:100%;
        img{
            width: 80%;
            heigh: 100%;
        }
    }
`
const TextContainer = styled.div`
    max-width: 65%;
    display:flex;
    flex-direction: column;
    @media (max-width: 768px) {
        max-width:100%;  
    }
`
const Text = styled.div`
    padding: 10px;
    margin: 0 20px;
    font-size: 20.5px;
    line-height: 1.5;
    @media (max-width: 768px) {
        width:100%;
        font-size: 16px;
        margin: 0;
        padding: 10px;
    }
`