import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import Portuguese from "../languages/pt-PT.json";
import English from "../languages/en-US.json";


export const Context = React.createContext();
const local = navigator.language;

let lang;
if (local === "pt-PT") {
    lang = Portuguese;
} else {
    lang = English;
}

function LangSelect(props) {
    const [locale, setLocale] = useState(local);
    const [messages, setMessages] = useState(lang);

    function selectLang(e) {
        const newLocale = e.target.value;
        setLocale(newLocale);
        if (newLocale === "pt-PT") {
            setMessages(Portuguese);
        } else {
            setMessages(English);
        }
    }

    return (
        <Context.Provider value={{ locale, selectLang }}>
            <IntlProvider messages={messages} locale={locale}>
                {props.children}
            </IntlProvider>
        </Context.Provider>
    )
}

export default LangSelect;