import React, { useContext, useState } from 'react';
import { Context } from "./LangSelect";
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import CloseIcon from '@mui/icons-material/Close';



function Header() {
    const context = useContext(Context);

    const [burgerStatus, setBurgerStatus] = useState(false);

    return (
        <Feader>
            <Container>
                <a href='#app.experience-title'><h1 className='Dawning'>Real Estate Meeting</h1></a>
                <Menu>
                    <a href='#app.experience-title'>
                        <FormattedMessage
                            id="app.experience-title-header"
                            defaultMessage="Experience"
                        /></a>
                    <a href='#app.about-title'><FormattedMessage
                        id="app.about-title"
                        defaultMessage="About Us"
                    /></a>

                    <a href='#app.contacts-title'>
                        <FormattedMessage
                            id="app.contacts-title"
                            defaultMessage="Contacts"
                        /></a>

                    <Languages>
                        <select onChange={context.selectLang}>
                            <option label="">{context.locale}</option>
                            <option label="Português" value="pt-PT">Português</option>
                            <option label="English" value="en-US">English</option>
                        </select>
                    </Languages>
                </Menu>
                <CustomMenu onClick={() => setBurgerStatus(true)}></CustomMenu>
                <BurgerNav show={burgerStatus}>
                    <CloseWrapper>
                        <CustomClose onClick={() => setBurgerStatus(false)} />
                    </CloseWrapper>
                    <ul>
                        <li><a href='#app.experience-title' onClick={() => setBurgerStatus(false)}><FormattedMessage
                            id="app.experience-title-header"
                            defaultMessage="Experience"
                        /></a></li>
                        <li><a href='#app.about-title' onClick={() => setBurgerStatus(false)}><FormattedMessage
                            id="app.about-title"
                            defaultMessage="About Us"
                        /></a></li>
                        <li><a href='#app.contacts-title' onClick={() => setBurgerStatus(false)}><FormattedMessage
                            id="app.contacts-title"
                            defaultMessage="Contacts"
                        /></a></li>
                        <li><select onChange={context.selectLang}>
                            <option label="">{context.locale}</option>
                            <option label="Português" value="pt-PT">Português</option>
                            <option label="English" value="en-US">English</option>
                        </select></li>
                    </ul>
                </BurgerNav>
            </Container>
        </Feader >
    )
}

export default Header

const Feader = styled.div`
    min-height: 60px;
    display: flex;
    padding: 0 20px;
    z-index: 1;
    background-color: white;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
`

const Container = styled.div`
    max-width: 1400px;
    margin: auto;
    justify-content: space-between;
    display: flex;
    h1{
        font-family: 'Dawning of a New Day', cursive;
    }
    width: 100vw;
`
const Menu = styled.div`
    display: flex;
    justify-content: end;
    margin: auto 0;
    a{
        padding: 0 15px;
        flex-wrap: nowrap;
    }
    @media (max-width: 768px) {
        display: none;
    }

`
const Languages = styled.div`
    margin-left: 15px;
`

const CustomMenu = styled(MenuOutlinedIcon)`
    @media screen and (min-width: 769px) {
        display: none !important;
    }
    cursor: pointer;
    margin: auto 0;
`

const BurgerNav = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 20;
    background: white;
    list-style: none;
    padding: 20px;
    display: flex;
    flex-direction: column;
    transform: ${props => props.show ? 'translateX(0)' : 'translateX(100%)'};
    li{
        padding: 15px 0;
    }
`
const CustomClose = styled(CloseIcon)`
    cursor: pointer;
    &:hover {
        color: #d39e00;
        }
`
const CloseWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`