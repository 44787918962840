import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import LocalCafeIcon from '@mui/icons-material/LocalCafe';
import TelegramIcon from '@mui/icons-material/Telegram';
import { common } from '@mui/material/colors';

function Form() {
    const intl = useIntl();
    const Name = intl.formatMessage({ id: 'app.form-name' })
    const Message = intl.formatMessage({ id: 'app.form-message' })
    const Send = intl.formatMessage({ id: 'app.form-send' })
    const [submit, setSubmit] = useState(false);
    const [formData, setFormData] = useState({
        "entry.1335273216": "",
        "emailAddress": "",
        "entry.286004314": "",
    });
    const handleInputData = (input) => (e) => {
        const { value } = e.target;

        setFormData((prevState) => ({
            ...prevState,
            [input]: value
        }));
    };

    async function handleSubmit(e) {
        e.preventDefault();
        setSubmit(true);

        let url = `https://docs.google.com/forms/u/1/d/e/1FAIpQLScTlSXsjmcQuixkPJpMAlMPFKAevzUOpfDWe3U6IA72ZaTDiw/formResponse?entry.1335273216=${formData["entry.1335273216"]}&emailAddress=${formData["emailAddress"]}&entry.286004314=${formData["entry.286004314"]}`;


        const res = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        });
    }

    return (
        <FormArea>


            <h3>Swing by for a cup of <LocalCafeIcon sx={{ fontSize: 19, marginBottom: -.3 }} />, or leave us a note:</h3>
            {submit ? (
                <div className="afterForm" style={{
                    textAlign: 'center',
                    padding: '20px 0',
                    fontSize: '30px',
                    fontWeight: '300'
                }}>
                    <FormattedMessage
                        id="app.thank-you"
                        defaultMessage="Thank you for your contact!"
                    />
                </div>
            ) : (
                <form onSubmit={handleSubmit} target="_self">
                    <Inputs>
                        <input
                            type="text"
                            name="entry.1335273216"
                            onChange={handleInputData("entry.1335273216")}
                            value={formData["entry.1335273216"]}
                            autoComplete={false}
                            required
                            placeholder={Name} />
                        <input
                            type="email"
                            name="emailAddress"
                            onChange={handleInputData("emailAddress")}
                            value={formData["emailAddress"]}
                            autoComplete={false}
                            required
                            placeholder='Email' />
                    </Inputs>
                    <textarea
                        name="entry.286004314"
                        onChange={handleInputData("entry.286004314")}
                        value={formData["entry.286004314"]}
                        autoComplete={false}
                        required
                        rows="1"
                        placeholder={Message} />
                    <ButtonWrapper>
                        <button type='submit'>
                            <TelegramIcon sx={{ fontSize: 16, marginBottom: -.3, color: common }} /> {Send}
                        </button>
                    </ButtonWrapper>
                </form >
            )}
        </FormArea >
    );
};

export default Form

const FormArea = styled.div`
    margin:auto;
    padding: 50px 0 80px 0;
    width:600px;
    h3{
        text-align:center;
    }
    @media (max-width: 768px) {
        max-width: 80vw;
        padding: 20px 0;
    }
    form{
        padding: 10px 0;
        display: flex;
        flex-direction: column;
    }
    textarea {
        resize: none;
        padding: 10px;
        margin: 10px 0 20px 0;
        min-width: 100%;
    }
`
const Inputs = styled.div`
    input{
        min-width: 49%;
        padding: 7px;
    }
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`
const ButtonWrapper = styled.div`
    width:100%;
    display: flex;
    justify-content: flex-end;
    button{
        color: #fff;
        background-color: #000;
        padding: 10px 16px;
        border: 1px solid black;
        text-transform: uppercase;
        font-weight: 600;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

        &:hover {
            background-color: #333;
            color: #d39e00;
        }
    }
`