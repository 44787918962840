import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import Form from './Form'
import AboutMe from './AboutMe';
import Contacts from './Contacts';


const handleAnchorClick = event => {
    console.log(event.currentTarget);
};

function Home() {
    return (
        <div>
            <Spacer></Spacer>
            <Container id='app.experience-title'>
                <PicsContainer>
                    <img src="images/o_servico.jpg"
                        srcSet='images/o_servico-500.webp 500w,
                                images/o_servico-1000.webp 1000w,
                                images/o_servico-1500.webp 1500w'
                        alt="living room, mother and son" />
                </PicsContainer>
                <TextContainer>
                    <Fade>
                        <Text style={{ borderTop: '1px solid #ddd', borderBottom: '1px solid #ddd' }}>
                            <FormattedMessage
                                id="app.experience-top"
                                defaultMessage="Buying a house is an unforgettable Experience. It is a moment in people's lives that implies decisions, commitments, changes, options, family, friends and many emotions. Whoever decides to buy a House needs to have confidence in whoever accompanies them in such an important decision. Our effort is focused on implementing that decision. By the knowledge that we have in the residential real estate market, we manage the entire purchase and sale process."
                            />
                        </Text>
                        <Text>
                            <h2><FormattedMessage
                                id="app.experience-title-middle"
                                defaultMessage="We are with you in this Experience!"
                            /></h2>
                        </Text>
                        <Text style={{ borderTop: '1px solid #ddd', borderBottom: '1px solid #ddd' }}>
                            <FormattedMessage
                                id="app.experience-bottom"
                                defaultMessage="We represent the buyer. We receive your briefing and develop the entire search and negotiation process with our business partners and owners, in order to find the property you want. You just have to follow us :-)"
                            />
                        </Text>
                    </Fade>
                </TextContainer>
            </Container>
            <Container>
                <Form />
            </Container>
            <Container id='app.about-title'>
                <PicsContainer>
                    <img src="images/sobre.jpg"
                        srcSet='images/sobre-500.webp 500w,
                                images/sobre-1000.webp 1000w,
                                images/sobre-1500.webp 1500w'
                        alt="Ladies at a fancy restaurant" />
                </PicsContainer>
                <TextContainer>
                    <Fade>
                        <Text style={{ border: 'none' }}>
                            <h2 style={{
                                textAlign: 'center',

                            }}><FormattedMessage
                                    id="app.about-title"
                                    defaultMessage="About Us"
                                /></h2>
                        </Text>
                        <Text style={{ borderBottom: '1px solid #ddd' }}>
                            <FormattedMessage
                                id="app.about-txt1"
                                defaultMessage="We are people focused on people who want to achieve a goal - to buy a HOME."
                            />
                            <br></br>
                            <FormattedMessage
                                id="app.about-txt2"
                                defaultMessage="A team with long and vast experience in the real estate market that brings together a diversified know-how at the service of those who want to buy an apartment or house in Lisbon and Cascais. We work surgically with each buyer client in closing the deal. Maturity, knowledge, sensitivity and common sense, empathy and adaptability are factors that make our performance the fulfillment of our clients' objectives."
                            />
                            <br></br>
                            <FormattedMessage
                                id="app.about-txt3"
                                defaultMessage="We are People who believe in People."
                            />
                        </Text>
                        <HDButtonWrap>
                            <Text style={{
                                textAlign: 'center',
                                borderBottom: 'none',
                                fontSize: '11px'
                            }}>
                                <em>
                                    <FormattedMessage
                                        id="app.about-button-disclaimer"
                                        defaultMessage="Real Estate Meeting a personalized service from House Door Real"
                                    />
                                </em>
                            </Text>

                            <a
                                onClick={handleAnchorClick}
                                href="https://www.housedoor.pt"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <HDButton>
                                    <h3>
                                        <FormattedMessage
                                            id="app.about-button"
                                            defaultMessage="Visit the site House Door"
                                        />
                                    </h3>
                                </HDButton>
                            </a>
                        </HDButtonWrap>
                    </Fade>
                </TextContainer>
            </Container>
            <hr style={{ display: 'block', height: '.1px', border: '0', borderTop: '1px solid #ddd', margin: '1em 0' }} />
            <AboutMe />
            <hr style={{ display: 'block', height: '.1px', border: '0', borderTop: '.1px solid #ddd', margin: '1em 0', padding: '0' }} />

            <Container>
                <Text>
                    <h2 style={{ justifyContent: 'center', textAlign: 'center' }}><FormattedMessage
                        id="app.contacts-txt"
                        defaultMessage="You bought your HOME! Be happy!"
                    /></h2>
                </Text>
            </Container>
            <Container id='app.contacts-title'>
                <Contacts />
            </Container>
        </div >

    )
}

export default Home

const Spacer = styled.div`
    height: 70px;
`
const Container = styled.div`
    display:  flex;
    align-items: center;
    max-width: 1400px;
    height: 100%;
    margin: auto;
    justify-content: center;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`
const PicsContainer = styled.div`
    width: auto;
    img{
        width: 45vw;
    }
    opacity: 0.90;
    @media (max-width: 768px) {
        img{
            width: 100vw;
        }
    }
`
const TextContainer = styled.div`
    width: 55vw;
    display:flex;
    flex-direction: column;
    @media (max-width: 768px) {
        width:100%;  
    }
`
const Text = styled.div`
    padding: 10px 0;
    margin: 0 20px;
    font-size: 20.5px;
    line-height: 1.5;
    @media (max-width: 768px) {
        width:100%;
        font-size: 16px;
        margin: 0;
        padding: 10px 15px;
    }
`
const HDButtonWrap = styled.div`
    padding: 5px 20px 40px 20px;
`
const HDButton = styled.div`
    display:flex;
    align-items: center;
    background-color: #fff;
    max-width: 259px;
    justify-content: center;
    margin: auto;
    margin-top: 5px;
    cursor: pointer;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    padding: 10px 16px;
    border: 1px solid black;
    font-weight: 600;
    &:hover {
        background-color: #f1f1f1;
        color: #d39e00;
    }
    
`