import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import PersonIcon from '@mui/icons-material/Person';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
function Contacts() {
    return (
        <div>
            <h1 style={{ marginTop: '20px' }}>
                <FormattedMessage
                    id="app.contacts-title"
                    defaultMessage="Contacts"
                />
            </h1>
            <List>
                <ul style={{ listStyle: 'none' }}>
                    <li><PersonIcon className='icons' /> The Place to Talk Real Estate, Lda.</li>
                    <li><LocationOnIcon className='icons' /> Praça Marquês de Pombal, 14. 1250-162 Lisboa.</li>
                    <li type="tel:+351912395098">
                        <LocalPhoneRoundedIcon className='icons' />
                        <FormattedMessage
                            id="app.contacts-tel"
                            defaultMessage="Phone:"
                        /> <a href="tel:+351912395098" target='_blank' rel="noreferrer">+351 912 395 098</a>
                    </li>
                    <li><EmailRoundedIcon className='icons' /> Email: <a href="mailto: info@housedoor.pt" target='_blank' rel="noreferrer">
                        info@housedoor.pt</a></li>
                    <li><WatchLaterIcon className='icons' />
                        <FormattedMessage
                            id="app.contacts-sch"
                            defaultMessage="09h to 19h - Monday to Saturday"
                        /></li>
                </ul>
            </List>
        </div>
    )
}

export default Contacts

const List = styled.div`
margin-bottom: 20px;
.icons{ 
    font-size: 20px;
    margin-right: 5px;
    margin-bottom: -3px;
    color: #565656;
    }
ul{
    padding: 20px;
}
li{
   padding: 5px;
}
`